

















































import HomeVideoAdd from "@/components/admin/home/HomeVideoAdd.vue";
import Vue from "vue";
import HomeService, { HomeVideoCreateDto } from "@/api/HomeService";

export default Vue.extend({
  components: {
    HomeVideoAdd
  },
  metaInfo: {
    title: "Home Videos"
  },
  data() {
    return {
      dialog: false,
      video: {
        url: ""
      } as HomeVideoCreateDto,
      headers: [
        { text: "Id", value: "id" },
        { text: "Url", value: "url" }, // disp
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      videos: [] as HomeVideoCreateDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        url: ""
      };
      this.video = newDto;
    },
    editItem(item: HomeVideoCreateDto) {
      this.video = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    deleteItem(item: HomeVideoCreateDto) {
      console.log(item);
      this.$swal
        .fire({
          title: "¿Seguro deseas borrarlo?",
          text: "No es posible revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, deseo eliminarlo"
        })
        .then(result => {
          if (result.isConfirmed && item.id) {
            HomeService.deleteVideo(item.id).then(() => {
              this.$swal
                .fire(
                  "Borrado!",
                  "Su video ha sido borrado con exito.",
                  "success"
                )
                .then(() => {
                  this.getCuentas();
                });
            });
          }
        });
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getCuentas() {
      HomeService.getVideos()
        .then(response => {
          this.videos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Cuentas Bancarias",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getCuentas();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
