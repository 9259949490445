import httpClient from "./http-client";

const ENDPOINT = "/configuracion/home/video";

export interface HomeVideoCreateDto {
  id?: number;
  url: string;
}

class HomeService {
  getVideos(): Promise<HomeVideoCreateDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  createVideo(createDto: HomeVideoCreateDto) {
    return httpClient.post(ENDPOINT, createDto);
  }
  deleteVideo(id: number) {
    return httpClient.delete(ENDPOINT + `/${id}`);
  }
}
export default new HomeService();
